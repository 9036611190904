import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Link } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import * as React from 'react';

import { PageSplit } from '../components/containers/page-split';
import { Panel } from '../components/containers/panel';
import { Button, Type as ButtonType } from '../components/elements/button';
import { Header } from '../components/layout/header';
import { Site } from '../components/shells/site';
import { Heading, Level } from '../components/typography/heading';
import { PageTitle } from '../components/typography/page-title';
import { Seo } from '../components/utilities/seo';

export default (): React.ReactElement => {
    return (
        <Site>
            <Seo title="Voorwaarden" />

            <Header />

            <PageSplit
                content={
                    <>
                        <PageTitle>Voorwaarden</PageTitle>
                        <div className={'prose'}>
                            <ul className={'pl-4'}>
                                <li>
                                    <Link to={'/voorwaarden/leefstijlcoaching'}>Download hier</Link> de voorwaarden die gelden voor
                                    leefstijlcoaching.
                                </li>
                                <li>
                                    <Link to={'/voorwaarden/powerwalking'}>Download hier</Link> de voorwaarden die specifiek voor de{' '}
                                    <Link to={'/powerwalking'}>lessen powerwalking</Link> gelden. Deze zijn ook van toepassing op
                                    proeflessen.
                                </li>
                                <li>
                                    Voor coaching of lessen waarbij we elkaar fysiek treffen is het van belang dat je de{' '}
                                    <Link to={'/voorwaarden/gezondheidscheck-coronavirus'}>coronavirus gezondheidscheck</Link> doet.
                                </li>
                                <li>
                                    Wil je weten hoe ik met de gegevens omga die worden verzameld als je de website bezoekt? Dit vind je in
                                    de <Link to={'/voorwaarden/privacy-en-cookies'}>privacy- en cookieverklaring</Link>.
                                </li>
                            </ul>
                        </div>

                        <Heading level={Level.Second} className={'mt-10'}>
                            Vragen?
                        </Heading>
                        <div className={'prose'}>
                            <p>Heb je vragen over de voorwaarden die ik hanteer? Neem dan gerust contact op.</p>
                        </div>
                        <div className={'flex flex-col sm:flex-row justify-start mt-6 gap-6'}>
                            <Button type={ButtonType.Link} url={'mailto:vicky@fitfoodforty.nl'}>
                                <FontAwesomeIcon icon={['far', 'envelope-open']} size={'1x'} className={'mr-2'} />
                                <span className={'font-bold'}>Stuur een e-mail</span>
                            </Button>

                            <Button type={ButtonType.Link} url={'https://wa.me/31641777848'}>
                                <FontAwesomeIcon icon={['fab', 'whatsapp']} size={'1x'} className={'mr-2'} />
                                <span className={'font-bold'}>WhatsApp</span>
                            </Button>
                        </div>
                    </>
                }
                sidebar={
                    <>
                        <div className={'w-full max-w-sm mx-auto'}>
                            <StaticImage src={'../../assets/images/section-split-vicky-2.jpg'} alt={''} className={'rounded-full'} />
                        </div>
                        <Panel className={'mt-12'}>
                            <strong>Fitfoodforty</strong>
                            <p>
                                Herdenkingsplein 32C
                                <br />
                                6211 PZ Maastricht
                            </p>
                            <p className={'mt-2'}>
                                KvK 73882488
                                <br />
                                BTW-ID NL001815704B36
                            </p>
                            <p className={'mt-2'}>NL39 KNAB 0258 4715 14</p>
                        </Panel>
                    </>
                }
            />
        </Site>
    );
};
